import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const startPlan = "<ul><li>0$ comisión mensual por manejo de cuenta</li><li>0$ monto requerido para abrir tu cuenta</li><li>Cuenta simplificada puedes recibir hasta $30,000 en depósitos al mes en tu cuenta</li><li>$60 comisión por proceso legal revisión de documentos durante solicitud de cuenta bancaria (pago único y sólo en caso de ser aprobada tu solictud)</li><li>GRATIS retiros de efectivo en supermercados en montos mínimos</li><li>Retiros en cajeros, comisión definida por cada banco</li><li>GRATIS Transferencias entre cuentas EVVA</li><li>$5.5 + IVA Por gastos operativos de Transferencias SPEI</li><li>$149 Si requieres una tarjeta de débito empresarial, es opcional</li><li>$10.35 + IVA Por cada depósito en efectivo en sucursales BBVA Bancomer y ScotiaBank</li><li>$30 + IVA por mes si no usas tu cuenta durante 90 días, esto es por los gastos operativos que genera una cuenta sin uso</li></ul>";
const growPlan ="<ul><li>0$ comisión mensual por manejo de cuenta</li><li>0$ monto requerido para aperturar tu cuenta</li><li><strong>Cuenta tradicional, sin límite máximo de depósitos al mes en tu cuenta</strong></li><li>0$ comisión proceso legal revisión de documentos durante apertura de cuenta bancaria (pago único y sólo en caso de ser aprobada tu solictud)</li><li>GRATIS retiros de efectivo en supermercados en montos mínimos</li><li>Retiros en cajeros, comisión definida por cada banco</li><li>GRATIS Transferencias entre cuentas EVVA</li><li>$5.5 + IVA Por gastos operativos de Transferencias SPEI</li><li>1 Tarjeta de débito empresarial incluida, reemplazo por $100</li><li>$10.35 + IVA Por cada depósito en efectivo en sucursales BBVA Bancomer y ScotiaBank</li><li>0$ comisión por inactividad de cuenta</li></ul>";
const freelancePlan ="<ul><li>0$ comisión mensual por manejo de cuenta</li><li>0$ monto requerido para abrir tu cuenta</li><li>Cuenta simplificada puedes recibir hasta $30,000 en depósitos al mes en tu cuenta</li><li>$60 comisión por proceso legal revisión de documentos durante solicitud de cuenta bancaria (pago único y sólo en caso de ser aprobada tu solictud)</li><li>GRATIS retiros de efectivo en supermercados en montos mínimos</li><li>Retiros en cajeros, comisión definida por cada banco</li><li>GRATIS Transferencias entre cuentas EVVA</li><li>$5.5 + IVA Por gastos operativos de Transferencias SPEI</li><li>$149 Si requieres una tarjeta de débito empresarial, es opcional</li><li>$10.35 + IVA Por cada depósito en efectivo en sucursales BBVA Bancomer y ScotiaBank</li><li>$30 + IVA por mes si no usas tu cuenta durante 90 días, esto es por los gastos operativos que genera una cuenta sin uso</li></ul>";

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "";

const Planes = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Por ahora el acceso a la plataforma es sólo por invitación. Regístrate y solicita tu acceso cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="planes" title="Planes que crecen contigo" description="Cuenta bancaria empresarial gratis y planes flexibles que crecen contigo."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
      }
      <MainSection title="Planes flexibles que crecen contigo" position="left" image="hero-pricing.png" actionLabel="Únete ahora" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path} />
      </MainSection>
      <PageSection maxColumns="3" title="Elige un plan con base a tus necesidades">
        <Card
          title="Empieza"
          description="<p><strong>PyMEs, Startups recién establecidas como SAS, SAPI de CV, SA de CV</p><p>Bajo volúmen de facturación, tu enfoque es el desarrollo de tu producto o servicio. Te queremos ayudar a empezar!</strong></p>"
          actionLabel="Abre tu cuenta"
          actionVariant="outlined"
          action={launchFormHandler}
          extraContent={startPlan}
          price="Gratis"
          image="starter-icon.png"
          imageWidth="160px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUp"
          padding
          shadow
        />
        <Card
          title="Crece"
          description="<p><strong>PyMEs, Startups recién establecidas como SAS, SAPI de CV, SA de CV</p><p>Ya tienes clientes y estás facturando, tu negocio se está consolidando. En esta fase queremos acelerar tu crecimiento!</strong></p>"
          actionLabel="Abre tu cuenta"
          action={launchFormHandler}
          extraContent={growPlan}
          price="$175/Mes"
          image="growing-icon.png"
          imageWidth="200px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUp"
          padding
          shadow
          main
        />
        <Card
          title="Freelancer"
          description="<p><strong>Para personas físicas con actividad empresarial y profesional.</p><p>Estás empezando tu propio negocio de forma individual, ya sea consultoria, tu tienda en línea, conductor en Uber o Didi</strong></p>"
          actionLabel="Muy pronto"
          actionVariant="outlined"
          action={launchFormHandler}
          extraContent={freelancePlan}
          price="Gratis"
          image="freelance-icon.png"
          imageWidth="200px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUpFreelancer"
          padding
          shadow
        />
      </PageSection>
      <PageSection maxColumns="2" title="Por qué elegirnos">
        <Card
            subtitle="Adiós burocracia"
            description="<p>Díle adiós a todos esos trámites complejos y largas esperas en sucursal, con EVVA obtienes la velocidad que necesitas para hacer crecer su negocio</p>"
            descriptionColor="#363636"
            image="startup-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Tú tienes el control"
            description="<p>Con EVVA tus finanzas están bajo control sin pisar una sucursal, mantente al tanto de negocio desde la comunidad de casa o tu celular</p>"
            descriptionColor="#363636"
            image="control-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Juntos somos más fuertes"
            description="<p>De emprendedores a emprendedores, sabemos lo difícil que es comenzar y hacer crecer tu negocio. Estamos aquí para ayudarte y guiarte en todo el camino</p>"
            descriptionColor="#363636"
            image="group-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Independencia Financiera"
            description="<p>Nuestra misión es darte la libertad financiera que mereces, con la finalidad que te enfoques en lo que realmente importa, tu negocio</p>"
            descriptionColor="#363636"
            image="freedom-icon.png"
            horizontal
            padding
            shadow
        />
      </PageSection>
      <SecondarySection
        title="¿Quieres conocernos mejor?"
        shortDescription="Déjanos ayudarte a crecer juntos."
        description="<p>Contáctanos directamente en: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Regístrate, es gratis"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default Planes